import React from "react";

// Components
import Layout from "../components/layout";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title="Sorry, that doesn't exist!">
      </Layout>
    );
  }
}

export default NotFoundPage;
